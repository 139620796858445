.text-image-grid {
  grid-template-columns: repeat(24, 1fr);

  &__subheading {
    grid-column: 3 / 23;
  }

  &__heading {
    grid-column: 3 / 23;
    margin-bottom: var(--spacing-30);

    @include media(min-tablet) {
      grid-column: 3 / 11;
      margin-bottom: var(--spacing-60);
    }
  }

  &__text {
    grid-column: 3 / 23;
    margin-bottom: var(--spacing-30);

    @include media(min-tablet) {
      grid-column: 14 / 23;
    }
  }

  img,
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__image {
    position: relative;
  }

  &__image:nth-of-type(1) {
    grid-column: 3 / 18;
    aspect-ratio: 1.51456311;

    @include media(min-tablet) {
      grid-column: 2 / 15;
    }
  }

  &__image:nth-of-type(2) {
    grid-column: 8 / 25;
    margin-top: var(--spacing-30);
    aspect-ratio: 0.815533981;

    @include media(min-tablet) {
      grid-column: 16 / 23;
      margin-top: var(--spacing-180);
    }
  }

  &__image:nth-of-type(3) {
    grid-column: 1 / 20;
    margin-top: var(--spacing-30);
    aspect-ratio: 1.53061224;

    @include media(min-tablet) {
      grid-column: 3 / 13;
      margin-top: 0;
      aspect-ratio: 0.835654596;
    }
  }

  &__image:nth-of-type(4) {
    grid-column: 3 / 23;
    margin-top: var(--spacing-30);
    aspect-ratio: 1.53061224;

    @include media(min-tablet) {
      grid-column: 15 / 25;
      margin-top: var(--spacing-120);
    }
  }
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-weight: 500;
  font-style: normal;
  font-display: auto;
  src: url('../src/assets/fonts/ITCAvantGardeStd-Md.woff2') format('woff2'), url('../src/assets/fonts/ITCAvantGardeStd-Md.woff') format('woff');
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-weight: 500;
  font-style: italic;
  font-display: auto;
  src: url('../src/assets/fonts/ITCAvantGardeStd-MdObl.woff2') format('woff2'), url('../src/assets/fonts/ITCAvantGardeStd-MdObl.woff') format('woff');
}

@font-face {
  font-family: 'ITC Avant Garde Gothic Std';
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: url('../src/assets/fonts/ITCAvantGardeStd-Bold.woff2') format('woff2'), url('../src/assets/fonts/ITCAvantGardeStd-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Judgement';
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  src: url('../src/assets/fonts/Judgement-Bold.woff2') format('woff2'), url('../src/assets/fonts/Judgement-Bold.woff') format('woff');
}

.overview {
  // z-index: 2;
  position: relative;

  // .c,
  // .container {
  //   position: static !important;
  // }

  .c {
    position: static !important;
  }

  @include media(max-tablet) {
    overflow: hidden;
  }
}

.overview__text-container {
  margin: 0 0 var(--spacing-90);

  @include media(min-tablet) {
    align-items: end;
  }
}

.overview__filter-container {
  grid-row-gap: var(--spacing-30);

  position: relative !important;
  z-index: 2 !important;

  margin: 0 0 var(--spacing-90);

  @include media(min-tablet) {
    grid-template-columns: repeat(auto-fit, minmax(calc(var(--col-3) - var(--spacing-30)), 1fr));
    grid-column-gap: var(--spacing-30);
    grid-row-gap: 0;

    padding: 0 var(--col-1);
  }
}

.overview__text-container + .overview__filter-container {
  margin-top: calc(var(--spacing-45) * -1);

  @include media(min-tablet) {
    margin-top: calc(var(--spacing-30) * -1);
  }
}

.overview__filter {
  @include media(max-tablet) {
    grid-column: 2 / 12;
  }

  height: var(--spacing-60);
}

.overview__reset-button {
  position: absolute;
  top: calc(100% + var(--spacing-15));
  right: var(--col-1);

  visibility: hidden;

  text-decoration: underline;

  opacity: 0;
}

.component--dark .overview__reset-button,
.component--color[style*='--color-green'] .overview__reset-button,
.component--color[style*='--color-pink'] .overview__reset-button {
  color: var(--color-white);
}

.overview__heading-wrap {
  grid-column: 2 / 12;

  h2 {
    position: relative;
  }

  @include media(min-tablet) {
    grid-column: 2 / 7;
  }
}

.overview .icon-scribble {
  top: 10%;
}

.overview__content-wrap {
  grid-column: 2 / 12;

  margin: 0;

  @include media(min-tablet) {
    grid-column: 7 / 12;

    > * {
      margin: 0 0 0 var(--spacing-60);

      width: calc(100% - var(--spacing-60));
    }
  }
}

.overview--half .overview__content-wrap {
  @include media(min-tablet) {
    > * {
      margin: 0 0 0 var(--spacing-30);

      width: calc(100% - var(--spacing-30));
    }
  }
}

.overview__grid-container {
  grid-row-gap: var(--spacing-60);

  // position: relative;
  // z-index: 1;

  @include media(min-tablet) {
    grid-template-columns: repeat(auto-fill, minmax(calc(var(--col-3) - var(--spacing-60)), 1fr));
    grid-column-gap: var(--spacing-60);

    padding: 0 var(--col-1);
  }
}

// .overview.has-cursor .overview__grid-container {
//   @include media(min-tablet) {
//     grid-template-columns: repeat(auto-fill, minmax(var(--col-3), 1fr));
//     grid-column-gap: 0;
//     grid-row-gap: 0;

//     padding: 0 var(--col-1) ;
//   }
// }

// stylelint-disable-next-line
.overview--half .overview__grid-container {
  @include media(min-tablet) {
    grid-template-columns: repeat(auto-fill, minmax(var(--col-5), 1fr)) !important;
    grid-column-gap: 0;
    grid-row-gap: 0;

    padding: 0 calc(var(--col-1) - var(--spacing-30));
  }
}

.overview__date-container {
  margin: var(--spacing-90) 0 var(--spacing-15);
}

.overview__text-container + .overview__date-container {
  margin: var(--spacing-120) 0 var(--spacing-15);
}

.overview__date {
  grid-column: 2 / 12;

  color: var(--color-pink);
}

.overview__item {
  position: relative;
  z-index: 0;
  padding-bottom: var(--spacing-50);

  width: 100%;
  height: 100%;

  text-decoration: none;

  border-bottom: 1px solid var(--color-black);

  @include media(max-tablet) {
    grid-column: 2 / 12;
  }
}

.overview--half .overview__item {
  @include media(min-tablet) {
    padding: var(--spacing-30) 35% var(--spacing-30) var(--spacing-30) !important;
  }
}

// .overview.has-cursor .overview__item {
//   @include media(min-tablet) {
//     padding: var(--spacing-50);
//   }
// }

.overview__visual-wrap {
  position: absolute;
  top: 50%;
  right: -45%;
  z-index: 0;

  width: var(--col-6);
  height: var(--col-6);

  overflow: hidden;

  border-radius: 50%;

  transform: translateY(-50%);

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.2);

    border-radius: 50%;
  }

  @include media(min-tablet) {
    display: none;
  }
}

.overview__visual {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;

  height: 100%;

  border-radius: 50%;

  object-fit: cover;
}

.overview__content {
  position: relative;
  z-index: 2;
  padding: var(--spacing-15) 0;

  h4 {
    transform: translateY(-1.04167vw);
  }

  button {
    transform: translateY(1.04167vw);
  }

  h4,
  button {
    transition: 500ms ease;
  }

  &:hover {
    h4,
    button {
      transform: translateY(0);
    }

    h3 {
      text-decoration: underline;
    }
  }

  @include media(min-tablet) {
    display: flex;
    flex-direction: column;
    align-items: start;

    height: 100%;
  }
}

.overview.has-cursor .overview__content {
  max-width: 75%;

  @include media(min-tablet) {
    max-width: none;
  }
}

.overview__subheading {
  margin: 0 0 var(--spacing-30);
  font-size: var(--font-s-base);
  line-height: var(--lh-base);
  font-weight: var(--font-w-medium);
  text-decoration: none;
}

.overview__heading {
  margin: 0 0 var(--spacing-30);

  text-decoration: none;

  @include media(min-tablet) {
    font-size: 2.5vw;
    line-height: 2.986vw;
  }
}

.overview--half .overview__heading {
  margin: 0 0 var(--spacing-15);
}

.component--dark .overview__subheading,
.component--color .overview__subheading {
  color: var(--color-white);
}

.component--dark .overview__heading,
.component--color .overview__heading {
  color: var(--color-white);
}

.overview__link {
  display: inline-block;
  font-weight: var(--font-w-bold);
  // margin: var(--spacing-30) 0 0;

  text-decoration: underline;

  @include media(min-tablet) {
    margin: auto 0 0;
  }
}

.is-light .overview__link {
  color: var(--color-pink);
}

.is-blue .overview__link {
  color: var(--color-yellow);
}

.is-blue .overview__subheading {
  color: var(--color-white);
}

.overview--half .overview__link {
  @include media(min-tablet) {
    margin: 0 0 0 var(--spacing-60);

    visibility: hidden;

    opacity: 0;

    transition: opacity 700ms;
  }
}

.overview--half .overview__item:hover .overview__link {
  @include media(min-tablet) {
    visibility: visible;

    opacity: 1;
  }
}

.overview__cursor {
  display: none;

  z-index: 1 !important;

  position: absolute;

  top: 0;
  left: 0;

  width: var(--col-3);
  height: var(--col-3);

  visibility: hidden;

  overflow: hidden;

  pointer-events: none;
  opacity: 0;

  border-radius: 50%;

  will-change: transform;

  @include media(min-tablet) {
    display: block;
  }
}

.overview__cursor-img {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.overview__msg {
  margin: 0;

  width: 100%;

  @include media(max-tablet) {
    grid-column: 2 / 12;
  }
}

.component--dark .overview__msg,
.component--color .overview__msg {
  color: var(--color-white);
}

// .button {
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;

//   position: relative;

//   padding: 0 var(--spacing-60);

//   height: var(--nav-button-size);

//   font-size: var(--font-s-button);
//   line-height: var(--lh-button);
//   font-weight: var(--font-w-bold);
//   color: var(--color-white);
//   text-decoration: none;

//   background-color: var(--color-pink);

//   transition: background-color 300ms ease;

//   @media (hover: hover) {
//     &:hover {
//       background-color: var(--color-black);
//     }
//   }
// }

// .button--green {
//   background-color: var(--color-green);
// }

// .button--white {
//   background-color: var(--color-white);
//   color: var(--color-black);

//   @media (hover: hover) {

//     &:hover {
//       background-color: var(--color-black);
//       color: var(--color-white);
//     }
//   }
// }

// .button--outline {
//   padding: 0 var(--spacing-30);

//   color: var(--color-black);

//   background-color: transparent;

//   border-radius: 15vw;
//   border: 2px solid var(--color-pink);

//   &:hover {
//     color: var(--color-white) !important;

//     background-color: var(--color-pink);
//   }
// }

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;

  background-color: var(--color-pink);

  border-radius: 50%;

  transition: background-color 200ms ease;
  will-change: background-color;

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-black);
    }
  }

  svg {
    width: 30%;
  }
}

.icon-button--sm {
  width: 60px;
  height: 60px;

  @include media(min-tablet) {
    width: 4.167vw;
    height: 4.167vw;
  }

  @include media(max-iphone5) {
    width: 14.063vw;
    height: 14.063vw;
  }
}

.icon-button--lg {
  width: 100px;
  height: 100px;
}

.icon-button--xl {
  width: 150px;
  height: 150px;
}

.icon-button--green {
  background-color: var(--color-green);
}

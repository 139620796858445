.search {
  &__subheading {
    margin: 0 0 var(--spacing-60);
    color: var(--color-green);
  }

  &__content {
    grid-column: 2 / -2;
  }

  &__item-heading {
    margin: var(--spacing-45) 0 3px;
  }

  &__item-text {
    span {
      color: var(--color-green);
    }
  }
}

.text {
  padding: var(--spacing-90) 0;

  &__heading {
    margin-bottom: vw(360, 50);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 50);
    }
  }

  &__heading,
  &__body,
  &__first-column,
  &__last-column {
    grid-column: 2 / 12;
  }

  &.is-single &__heading,
  &__body {
    @include media(min-tablet) {
      grid-column: 4 / 10;
    }
  }

  &.is-double &__heading {
    @include media(min-tablet) {
      padding-right: vw(1440, 630);
    }
  }

  &__first-column {
    @include media(min-tablet) {
      grid-column: 2 / 7;
      padding-right: vw(1440, 30);
    }
  }

  &__last-column {
    @include media(min-tablet) {
      grid-column: 7 / 12;
      padding-left: vw(1440, 30);
    }
  }
}

.text-img {
  background: #f9f9f9;
}

.c + .text-img {
  @include media(max-tablet) {
    margin-top: 0;
  }
}

.text-img__container {
  @include media(min-tablet) {
    grid-column-gap: var(--spacing-60);
    // align-items: center;
  }
}

.text-img--img-left .text-img__container {
  @include media(min-tablet) {
    grid-column-gap: 0;
  }
}

.text-img__text {
  margin-top: var(--spacing-40);

  @include media(min-tablet) {
    margin-top: var(--spacing-40);
  }
}

.text-img__content {
  grid-column: 2 / 12;
  order: 1;

  @include media(min-tablet) {
    grid-column: 2 / 7;
    order: 0;
    margin-top: var(--spacing-60);
    margin-left: 0;
  }
}

.text-img--img-left .text-img__content {
  @include media(min-tablet) {
    order: 1;
    grid-column: 7 / 12;
    margin-left: var(--col-0-5);
  }
}

.text-img__img-wrap {
  grid-column: span 12;
  order: 0;

  position: relative;

  margin: 0 0 var(--spacing-50);

  @include media(min-tablet) {
    order: 1;
    grid-column: 7 / 13;

    margin: 0;
    margin-left: var(--col-0-5);
  }
}

.text-img__img-wrap.portrait {
  grid-column: span 12;
  order: 0;

  position: relative;

  margin: 0 0 var(--spacing-50);

  @include media(min-tablet) {
    order: 1;
    grid-column: 8 / 13;

    margin-left: 0;
  }
}

.text-img--img-left .text-img__img-wrap {
  margin-left: 0;
  margin-right: var(--col-0-5);

  @include media(min-tablet) {
    grid-column: 1 / 7;
    order: 0;
  }
}

.text-img.has-illustration .text-img__img-wrap {
  text-align: center;

  @include media(max-tablet) {
    margin: var(--spacing-60) 0 var(--spacing-30);
  }
}

.text-img .icon-bunny,
.text-img .icon-butterfly,
.text-img .icon-rocket {
  width: 70%;

  @include media(min-tablet) {
    width: 50%;
  }
}

.text-img__caption-1,
.text-img__caption-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: vw(360, 12) vw(360, 25);

  @include media(min-tablet) {
    padding: vw(1440, 15) vw(1440, 28);
  }
}

.text-img__caption-1 {
  right: var(--col-0-5);
  transform: translateY(50%);
}

.text-img__caption-2 {
  right: var(--col-1);
  bottom: var(--spacing-5);
  transform: translateY(-50%);
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 vw(360, 50);
  height: vw(360, 55);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  font-weight: var(--font-w-bold);
  transition: background-color 300ms ease, color 300ms ease;

  @include media(min-tablet) {
    padding: 0 vw(1440, 50);
    height: vw(1440, 55);
  }

  &.is-primary {
    background-color: var(--color-yellow);
  }

  &.is-secondary {
    background-color: var(--color-white);
  }

  &.is-primary,
  &.is-secondary {
    color: var(--color-black);

    &:hover {
      color: var(--color-white);
      background-color: var(--color-pink);
    }
  }

  &.is-link {
    padding: 0;
    color: var(--color-white);
    text-decoration: underline;
  }

  &.is-primary + &.is-link,
  &.is-secondary + &.is-link {
    padding: 0 vw(360, 50);

    @include media(min-tablet) {
      padding: 0 vw(1440, 50);
    }
  }
}

body {
  letter-spacing: spacing(0);
  font-family: var(--font-f-body);
  font-size: var(--font-s-body);
  line-height: var(--lh-body);
}

.heading {
  font-family: var(--font-f-heading);
  letter-spacing: spacing(0);
  font-weight: var(--font-w-bold);

  &.is-xl {
    font-family: var(--font-f-brand);
    font-size: var(--font-s-heading-xl);
    line-height: var(--lh-heading-xl);
    letter-spacing: spacing(-25);
    text-transform: uppercase;
  }

  &.is-lg {
    font-size: var(--font-s-heading-lg);
    line-height: var(--lh-heading-lg);
    letter-spacing: spacing(-25);
  }

  &.is-md {
    font-size: var(--font-s-heading-md);
    line-height: var(--lh-heading-md);
    letter-spacing: spacing(-25);
  }

  &.is-sm {
    font-size: var(--font-s-heading-sm);
    line-height: var(--lh-heading-sm);
    letter-spacing: spacing(-10);
  }

  &.is-xs {
    font-size: var(--font-s-heading-sm);
    line-height: var(--lh-heading-sm);
    letter-spacing: spacing(-10);
  }
}

.intro {
  letter-spacing: spacing(0);
  font-size: var(--font-s-intro);
  line-height: var(--lh-intro);
  font-weight: var(--font-w-bold);
}

.subheading {
  margin-bottom: vw(360, 30);
  font-size: var(--font-s-subheading);
  line-height: var(--lh-subheading);
  font-weight: var(--font-w-bold);

  @include media(min-tablet) {
    margin-bottom: vw(1440, 55);
  }
}

.quote {
  letter-spacing: spacing(0);
  font-size: var(--font-s-quote);
  line-height: var(--lh-quote);
}

.is-dark,
.is-blue {
  .heading {
    color: var(--color-white);
  }
}

.richtext {
  a {
    font-weight: var(--font-w-bold);
    color: var(--color-pink);
    border-bottom: vw(360, 1.5) solid var(--color-pink);

    @include media(min-tablet) {
      border-bottom: vw(1440, 1.5) solid var(--color-pink);
    }
  }

  p + p {
    margin-top: var(--spacing-30);
  }

  p + ul,
  p + ol {
    margin-top: vw(360, 30);

    @include media(min-tablet) {
      margin-top: vw(1440, 30);
    }
  }

  ul + p,
  ol + p {
    margin-top: vw(360, 30);

    @include media(min-tablet) {
      margin-top: vw(1440, 30);
    }
  }

  h3,
  h4 {
    margin-top: var(--spacing-30);
    margin-bottom: var(--spacing-20);
    font-weight: var(--font-w-bold);
  }

  p + h3,
  ul + h3,
  ol + h3,
  p + h4,
  ul + h4,
  ol + h4 {
    margin-top: var(--spacing-50);
  }

  li {
    padding-left: 10px;
    margin-left: 20px;
    list-style: disc;
  }

  strong {
    font-size: var(--font-s-intro);
    line-height: var(--lh-intro);
  }
}

.is-dark,
.is-blue {
  .richtext {
    color: var(--color-white);
  }
}

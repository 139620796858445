html,
body {
  height: 100%;
}

body {
  margin: 0;
  background: #f9f9f9;

  // overscroll-behavior-y: none;
}

body.is-device {
  -webkit-overflow-scrolling: touch;
}

* {
  box-sizing: border-box;
}

.app {
  // position: fixed;
  // top: 0;
  // left: 0;
  // bottom: 0;
  // right: 0;

  // z-index: 1;
}

body.is-device .app {
  position: relative;

  // overflow: hidden;
}

main {
  @include media(min-tablet) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

body.is-device main {
  position: relative;

  top: unset;
  left: unset;
}

section {
  position: relative;

  width: 100%;
  height: auto;
}

body:not(.is-device) [data-scroll] {
  will-change: transform;
}

.page {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;

  will-change: transform;

  &:nth-child(2) {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.js-move-y {
  visibility: hidden;

  opacity: 0;

  transform: translateY(50px);
}

.js-fade {
  visibility: hidden;

  opacity: 0;
}

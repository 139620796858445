.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  z-index: 99;

  top: vw(360, 40);
  padding: 0 vw(360, 60) 0 var(--col-1);

  width: 100%;

  @include media(min-tablet) {
    top: vw(1440, 45);
    padding: 0 vw(1440, 60) 0 var(--col-1);
  }

  &__menu-link {
    display: inline-block;
    position: relative;
    padding: vw(1440, 10) 0;
    color: var(--color-white);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1.5px;
      width: 0;
      background: var(--color-white);
      transition: width 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    }

    &:hover::after {
      width: 100%;
    }
  }

  &__menu {
    display: none;

    @include media(min-tablet) {
      display: flex;

      padding-right: vw(1440, 82);

      column-gap: vw(1440, 60);

      &.is-hidden {

        pointer-events: none;
        opacity: 0;
      }
    }
  }

  &__drop {
    position: absolute;
    top: vw(1440, 56);
    padding-top: vw(1440, 30);
    visibility: hidden;
    line-height: vw(1440, 30);
    opacity: 0;
    transition: all 0.5s cubic-bezier(0.34, 1.56, 0.64, 1);
    transition-delay: 0s;

    &.has-1-col {
      left: 50%;
      transform: translateX(-50%);
    }

    &.has-2-cols,
    &.has-3-cols,
    &.has-4-cols {
      right: vw(1440, 60);
    }
  }

  &__drop-inner {
    padding: vw(1440, 60);
    background-color: var(--color-white);

    &.has-2-cols,
    &.has-3-cols,
    &.has-4-cols {
      display: grid;
      align-items: start;
      column-gap: vw(1440, 40);
      row-gap: vw(1440, 40);
    }

    &.has-2-cols {
      grid-template-columns: repeat(2, 1fr);
    }

    &.has-3-cols {
      grid-template-columns: repeat(3, 1fr);
    }

    &.has-4-cols {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__menu-heading {
    margin: 0;
    font-size: vw(1440, 16);
    font-weight: bold;
  }

  &__menu-item {
    white-space: nowrap;

    &.is-relative {
      position: relative;

      .nav__menu-heading {
        display: none;
      }
    }

    &:hover {
      .nav__drop {
        top: vw(1440, 46);
        visibility: visible;
        opacity: 1;
        transition-delay: 0.2s;
      }
    }

    &.is-relative:last-child {
      position: static;

      .nav__drop {
        right: vw(1440, 60) !important;
        left: auto;
        transform: translateX(0);
      }
    }
  }

  &__logo {
    height: vw(360, 37);
    width: vw(360, 92);

    @include media(min-tablet) {
      height: vw(1440, 55);
      width: vw(1440, 137);
    }
  }

  &__column {
    position: relative;
    padding-bottom: vw(1440, 25);
    border-bottom: 1px solid var(--color-black);
  }

  &__menu-icon img {
    position: absolute;
    top: vw(1440, 6);
    left: 0;
    width: vw(1440, 45);
    height: auto;
  }

  &__submenu-item {
    font-size: vw(1440, 14);
    white-space: nowrap;
  }

  &__submenu-link {
    color: var(--color-black);

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: vw(1440, 1.5);
    }
  }

  &__search-button {
    padding: vw(1440, 10) 0;

    width: vw(1440, 22);
    height: vw(1440, 24);

    fill: var(--color-white);
  }
}

.mobile {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 100;
  left: -100%;

  width: 100%;

  overflow-y: scroll;

  background-color: var(--color-white);
  transition: left 0.5s cubic-bezier(0.7, 0, 0.84, 0);

  &.is-open {
    left: 0;
  }

  @include media(min-tablet) {
    display: none;
  }

  &__menu {
    padding: var(--col-4) var(--col-1) var(--col-1) var(--col-1);
  }

  &__menu-item {
    margin-bottom: vw(360, 40);
  }

  &__icon {
    transform: rotate(0deg);
    transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);

    path {
      fill: var(--color-black);
    }
  }

  &__menu-link .mobile__icon {
    right: 0;
    position: absolute;
    top: 0;
    height: vw(360, 25);
    transform: rotate(0deg);
    transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }

  &__menu-heading {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: vw(360, 16);
    font-weight: bold;
  }

  &__menu-heading .mobile__icon {
    margin-left: vw(360, 5);
    height: vw(360, 16);
  }

  &__menu-heading.is-open .mobile__icon,
  &__menu-link.is-open .mobile__icon {
    transform: rotate(90deg);
  }

  &__menu-link {
    position: relative;
    // margin-bottom: vw(360, 20);
    font-size: vw(360, 30);
    font-weight: bold;
  }

  &__submenu-link,
  &__menu-heading {
    margin-bottom: vw(360, 15);
    font-size: vw(360, 20);
  }

  &__menu-link,
  &__submenu-link,
  &__menu-heading {
    display: block;
    width: 100%;
    text-align: left;
    transition: color 200ms ease;

    &:hover {
      color: var(--color-green);
    }
  }

  &__menu-group {
    // display: none;
    height: 0;
    overflow: hidden;

    &.is-open {
      // display: block;
    }
  }

  &__menu-item.no-sub-groups .mobile__menu-heading {
    display: none;
  }

  &__menu-item.has-sub-groups .mobile__submenu {
    //display: none;
    height: 0;
    overflow: hidden;
    // padding-bottom: vw(360, 20);

    &:last-child {
      padding-bottom: 0;
    }

    &.is-open {
      //display: block;
    }
  }
}

.nav-button-wrap {
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 101;

  width: var(--nav-button-size);
  max-height: vw(360, 135);

  @include media(min-tablet) {
    display: none;
  }
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  position: absolute;
  top: vw(360, 20);
  right: vw(360, 30);
  z-index: 102;

  width: vw(360, 70);
  height: vw(360, 70);

  cursor: pointer;

  background-color: var(--color-pink);

  border-radius: 50%;

  transition: background-color 200ms ease;
  will-change: background-color;

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-black);
    }
  }

  @include media(min-tablet) {
    display: none;
  }

  svg {
    width: 30%;
  }

  span {
    display: block;

    margin: 0 0 vw(360, 7);

    width: vw(360, 24);
    height: vw(360, 2);

    background-color: var(--color-white);

    transition: 200ms;

    &:last-child {
      margin: 0;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: var(--color-pink);
    }
  }
}

.navbar--hidden + .nav-button-wrap .nav-button {
  pointer-events: none;

  transform: translateY(calc(var(--navbar-height) * -1));
}

.navigation.is-open .nav-button-wrap .nav-button span {
  &:first-child {
    transform: translateY(vw(360, 8)) rotate(45deg);
  }

  &:nth-child(2) {
    opacity: 0;
  }

  &:last-child {
    transform: translateY(vw(360, -10)) rotate(-45deg);
  }
}

.navigation.is-dark {
  .nav__logo-roc {
    fill: var(--color-black);
  }

  .nav__logo-teraa {
    fill: var(--color-green);
  }

  .nav__menu-link {
    color: var(--color-black);
  }

  .nav__menu-link::after {
    background: var(--color-black);
  }
}

.navigation.is-light {
  .nav__logo {
    g,
    path {
      fill: var(--color-white);
    }
  }
}

.contact-button {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 11;

  padding: 10px 20px;

  text-decoration: none;

  background-color: #fec131;

  @include media(max-tablet) {
    // reset icon-button styles
    width: auto;
    height: auto;

    border-radius: 0;

    &:hover {
      background-color: #fdc130;
    }
  }

  @include media(min-tablet) {
    bottom: 30px;
    right: 45px;

    padding: 0;

    background-color: var(--color-white);
  }

  svg {
    width: 60%;

    fill: var(--color-white);
  }

  &:hover {
    @include media(min-tablet) {
      background-color: var(--color-white);
    }
  }
}

.contact-button__text {
  margin: 0;

  font-size: 15px;
  font-weight: var(--font-w-bold);

  @include media(min-tablet) {
    display: none;
  }
}

.contact-button__hand,
.contact-button__circle {
  @include media(max-tablet) {
    display: none;
  }
}

.contact-button__icon {
  width: 80px;
  height: 80px;

  pointer-events: none;

  transition: transform 300ms ease;
  will-change: transform;
}

.contact-button:hover .contact-button__icon {
  transform: translateY(-30px);
}

.contact-button__circle {
  position: absolute;

  width: 120px !important;
  height: 120px;

  visibility: hidden;

  opacity: 0;

  transition: opacity 300ms;

  text {
    font-size: 12px;
    text-transform: uppercase;

    transform: rotate(-5deg);
    transform-origin: center center;

    fill: #fdc130;
  }
}

.contact-button:hover .contact-button__circle {
  visibility: visible;

  opacity: 1;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance:none) {
    .contact-button__circle {
      top: -7px;
      right: -15px;

      height: 110px;
    }
  }
}

.video_banner {
  &__container-video_cta,
  &__container-video {
    position: relative;
    overflow: hidden;
  }

  &__container-video {
    aspect-ratio: 0.99512195;

    @include media(min-tablet) {
      aspect-ratio: 2.19512195;
    }
  }

  &__container-video_cta {
    aspect-ratio: 0.71428571;

    @include media(min-tablet) {
      aspect-ratio: 1.71428571;
    }
  }

  &__container-image {
    position: relative;
    overflow: hidden;
    aspect-ratio: 0.71428571;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include media(min-tablet) {
      aspect-ratio: 1.71428571;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: vw(360, 80);
    cursor: pointer;
    transform: translate(-50%, -50%);

    @include media(min-tablet) {
      width: vw(1440, 150);
    }
  }

  &__video-poster img {
    width: 100%;
  }

  &__video-poster,
  &__video-loop,
  &__video-container,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.25);
  }

  &__video-loop {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__video-container {
    display: none;
    position: fixed;
    z-index: 5;

    background: var(--color-black);
  }

  &__video {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    transform: translateX(-50%);

    @include media(min-tablet) {
      width: auto;
    }
  }

  &__close-button {
    position: absolute;
    top: vw(360, 15);
    right: vw(360, 15);
    width: vw(360, 40);
    cursor: pointer;

    @include media(min-tablet) {
      top: vw(1440, 20);
      right: vw(1440, 20);
      width: vw(1440, 80);
    }
  }

  &__caption-1-bottom,
  &__caption-2-bottom,
  &__caption-1-top,
  &__caption-2-top {
    position: absolute;
    padding: vw(360, 12) vw(360, 25);

    @include media(min-tablet) {
      padding: vw(1440, 15) vw(1440, 28);
    }
  }

  &__caption-1-bottom {
    bottom: 0;
    left: var(--col-1);
    transform: translateY(50%);
  }

  &__caption-2-bottom {
    left: var(--col-0-5);
    bottom: var(--spacing-5);
    transform: translateY(-50%);
  }

  &__caption-1-top {
    bottom: 0;
    left: var(--col-2);
    transform: translateY(50%);

    @include media(min-tablet) {
      bottom: unset;
      left: unset;
      top: 0;
      right: var(--col-1);
      transform: translateY(-150%);
    }
  }

  &__caption-2-top {
    bottom: var(--spacing-5);
    left: var(--col-1);
    transform: translateY(-50%);

    @include media(min-tablet) {
      bottom: unset;
      left: unset;
      top: var(--spacing-5);
      right: var(--col-0-5);
      transform: translateY(-50%);
    }
  }

  &__content-wrapper {
    position: absolute;
    top: 50%;
    left: var(--col-1);
    width: auto;
    color: var(--color-white);
    transform: translateY(-50%);

    @include media(min-tablet) {
      width: vw(1440, 520);
    }
  }

  &__heading {
    margin-bottom: vw(1440, 30);
  }

  &__icon-heading {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    width: vw(360, 70);
    transform: translate(-50%, -50%);

    @include media(min-tablet) {
      width: vw(1440, 105);
    }
  }
}

.form__group--select {
  z-index: 1;
}

label {
  display: block;

  margin: 0 0 var(--spacing-15);

  font-size: var(--font-s-subheading);
  line-height: var(--font-s-subheading);
  font-weight: var(--font-w-bold);
}

.component--dark label,
.component--color:not([style*='--color-grey']) label {
  color: var(--color-white);
}

input,
textarea {
  font-size: var(--font-s-base);
  line-height: var(--lh-base);

  border: 1px solid var(--color-black);
  border-radius: 0;
}

input {
  display: flex;
  align-items: center;

  padding: 0 var(--spacing-30);

  height: var(--spacing-60);
}

.component--dark input,
.component--color:not([style*='--color-grey']) input {
  border-color: var(--color-white);
}

textarea {
  padding: var(--spacing-30);

  min-height: var(--grid-6);

  @include media(min-tablet) {
    min-height: var(--grid-2);
  }
}

.select {
  display: inline-block;

  position: relative;

  width: 100%;
  height: var(--spacing-60);

  cursor: pointer;

  color: var(--color-black);
}

.component--dark .select,
.component--color:not([style*='--color-grey']) .select {
  color: var(--color-white);
}

.select__hidden {
  display: none;
}

.select__styled {
  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  padding: 0 var(--spacing-30);

  width: 100%;
  height: var(--spacing-60);

  font-size: var(--font-s-base);
  line-height: var(--lh-base);
  font-weight: var(--font-w-bold);

  border: 1px solid var(--color-black);

  transition: all 200ms ease;

  &::after {
    content: '';

    position: absolute;
    top: 50%;
    right: var(--spacing-30);

    width: vw(360, 17);
    height: vw(360, 12);

    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNCA4Ij4KICA8Zz4KICAgIDxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZjMWY2MyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMyAxTDcgNyAxIDEiPjwvcGF0aD4KICA8L2c+Cjwvc3ZnPg==');
    background-size: vw(360, 17) vw(360, 12);

    transform: translateY(-50%);
    transform-origin: center;
    transition: transform 150ms ease;

    @include media(min-tablet) {
      width: vw(1440, 17);
      height: vw(1440, 12);
      background-size: vw(1440, 17) vw(1440, 12);
    }
  }

  &:hover {
    background-color: var(--color-white);
  }

  &.is-open::after {
    transform: translateY(-50%) rotate(180deg);
  }
}

.component--dark .select__styled {
  border-color: var(--color-white);

  &:hover {
    background-color: var(--color-black);
  }
}

.select__options {
  display: none;

  position: absolute;
  top: var(--spacing-60);
  left: 0;
  z-index: 2;

  padding: 5px 0;
  margin: -1px 0 0;

  width: 100%;

  background-color: var(--color-white);

  border: 1px solid var(--color-black);

  list-style: none;
}

.component--dark .select__options {
  background-color: var(--color-black);

  border-color: var(--color-white);
}

.select__styled.is-open + .select__options {
  display: block;
}

.select__option {
  padding: 5px var(--spacing-30);
  margin: 0;

  transition: all 200ms ease;

  &:hover {
    color: var(--color-pink);
  }

  &[rel='hide'] {
    display: none;
  }
}

.component--color[style*='--color-green'],
.component--color[style*='--color-pink'] {
  .select__styled {
    border-color: var(--color-white);
  }

  .select__options {
    border-color: var(--color-white);
  }
}

.component--color[style*='--color-green'] {
  .select__styled:hover {
    background-color: var(--color-green);
  }

  .select__options {
    background-color: var(--color-green);
  }
}

.component--color[style*='--color-pink'] {
  .select__styled:hover {
    background-color: var(--color-pink);
  }

  .select__options {
    background-color: var(--color-pink);
  }

  .select__option:hover {
    color: var(--color-white);
    text-decoration: underline;
  }
}

.grid_links {
  padding: vw(360, 30) 0;
  color: var(--color-white);

  @include media(min-tablet) {
    padding: vw(1440, 30) 0;
  }

  &__container {
    grid-column: 2 / 12;
    grid-gap: vw(360, 30) 0;
    display: grid;

    @include media(max-tablet) {
      grid-template-columns: 1fr !important;
    }

    @include media(min-tablet) {
      grid-gap: vw(1440, 30);
    }
  }

  &__block {
    display: grid;
    padding: vw(360, 30);

    @include media(min-tablet) {
      padding: vw(1440, 60) vw(1440, 30);
    }
  }

  &__link {
    align-self: end;
    margin-top: vw(360, 15);
    color: var(--color-yellow);

    @include media(min-tablet) {
      margin-top: vw(1440, 30);
    }
  }

  &__heading {
    margin-bottom: vw(360, 15);

    @include media(min-tablet) {
      margin-bottom: vw(1440, 30);
      min-height: vw(1440, 50);
    }
  }
}

.component--dark + .banners {
  margin: 0;
}

.banners__container {
  grid-row-gap: 0;

  @include media(min-tablet) {
    grid-template-columns: repeat(auto-fit, minmax(var(--col-3), 1fr));
  }
}

.banners__banner {
  display: flex;
  align-items: center;

  text-align: center;

  @include media(max-tablet) {
    grid-column: span 12;
    --aspect-ratio: 0.5 !important;
  }
}

.banners__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 var(--col-1);

  width: 100%;

  @include media(min-tablet) {
    padding: 0 var(--spacing-60);
  }
}

.banners__icon {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto var(--spacing-30);

  width: 21.918vw;
  height: 21.918vw;

  border-radius: 50%;

  @include media(min-tablet) {
    margin: 0 auto var(--spacing-45);

    width: 5.556vw;
    height: 5.556vw;
  }
}

.banners__text {
  margin: var(--spacing-30) 0;

  @include media(min-tablet) {
    margin: var(--spacing-45) 0;
  }
}

.banners__link {
  display: block;
  font-size: vw(360, 16);
  font-weight: bold;
  text-decoration: underline;

  @include media(min-tablet) {
    font-size: vw(1440, 16);
  }
}

.quote {
  padding: var(--spacing-90) 0;

  @include media(min-tablet) {
    padding: var(--spacing-120) 0;
  }

  &__wrap {
    grid-column: 3 / 11;
  }

  strong {
    display: inline-block;
    margin-right: vw(1440, 20);
  }

  &__text {
    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }

  &__author {
    margin-top: vw(1440, 50);
    color: var(--color-yellow);
    font-size: var(--font-s-intro);
    line-height: var(--lh-intro);
  }

  &.is-light &__author {
    color: var(--color-pink);
  }
}

.text-download__content {
  grid-column: 2 / 12;

  @include media(min-tablet) {
    grid-column: 2 / 7;
  }
}

.text-download__heading {
  margin-bottom: var(--spacing-50);
}

.text-download__subheading {
  grid-column: 2 / 12;

  color: var(--color-pink);

  @include media(min-tablet) {
    padding-right: var(--col-5);
  }
}

.text-download__downloads {
  grid-column: 2 / 12;
  max-width: 100%;

  @include media(max-tablet) {
    margin-top: vw(360, 40);
  }

  @include media(min-tablet) {
    grid-column: 8 / 11;
  }
}

.text-download__list {
  padding: 0;

  list-style: none;
}

.text-download__list-item {
  margin: 0 0 5px;

  &:last-child {
    margin: 0;
  }
}

.text-download__button {
  display: flex;
  justify-content: flex-start;
  width: auto;
  color: var(--color-white);
  background-color: var(--color-black);

  &::before {
    content: '';

    display: block;

    position: absolute;
    top: 50%;
    left: 6vw;

    width: 5.205vw;
    height: 4.658vw;

    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOSAxNyI+CiAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTcuNCAxMS40aC0uNXYyaC40Yy43IDAgLjktLjUuOS0xIDAtLjIgMC0uNS0uMi0uNyAwLS4xLS4yLS4zLS42LS4zem0tMy4zLjFjLS4xLS4xLS4zLS4xLS40LS4xaC0uM3YuOWguM2MuMSAwIC4yIDAgLjQtLjEuMS0uMS4yLS4yLjItLjRzLS4xLS4zLS4yLS4zeiI+PC9wYXRoPgogIDxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik0xNSAwSDRDMS44IDAgMCAxLjggMCA0djljMCAyLjIgMS44IDQgNCA0aDExYzIuMiAwIDQtMS44IDQtNFY0YzAtMi4yLTEuOC00LTQtNHpNNC44IDEyLjhjLS4zLjItLjUuMy0xLjEuM2gtLjN2MS4yaC0xdi0zLjdoMS4yYy43IDAgLjkuMSAxLjEuMy4zLjIuNC42LjQgMSAuMS4zLS4xLjctLjMuOXptMi41IDEuNUg2di0zLjdoMS4yYy40IDAgMS4yIDAgMS42LjcuMy40LjMuNy4zIDEuMi4xLjktLjQgMS44LTEuOCAxLjh6bTQuOC0yLjlIMTF2LjZoMXYuOGgtMXYxLjRoLS45di0zLjdoMnYuOXoiPjwvcGF0aD4KPC9zdmc+Cg==');
    background-size: 5.205vw 4.658vw;

    transform: translateY(-50%);

    @include media(min-tablet) {
      left: 1.5vw;

      width: 1.319vw;
      height: 1.181vw;

      background-size: 1.319vw 1.181vw;
    }
  }

  &:hover {
    background-color: var(--color-pink);
  }
}

.text-download__label {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.is-doc::before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOSAxNyI+CiAgPGc+CiAgICA8Zz4KICAgICAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTMgMTEuM2gtLjR2MkgzYy43IDAgLjktLjUuOS0xYTEgMSAwIDAgMC0uMi0uNy45MS45MSAwIDAgMC0uNy0uM3ptMy45LS4xYTEgMSAwIDAgMC0xIDEuMSAxIDEgMCAxIDAgMiAwIDEuMDkgMS4wOSAwIDAgMC0xLTEuMXoiPjwvcGF0aD4KICAgICAgPHBhdGggZmlsbD0iI2ZmZiIgZD0iTTE1IDBINGE0IDQgMCAwIDAtNCA0djlhNCA0IDAgMCAwIDQgNGgxMWE0IDQgMCAwIDAgNC00VjRhNCA0IDAgMCAwLTQtNHpNMi45IDE0LjJIMS42di0zLjdoMS4yYTEuNjkgMS42OSAwIDAgMSAxLjYuNyAxLjU2IDEuNTYgMCAwIDEgLjMgMS4yYy4xLjktLjQgMS44LTEuOCAxLjh6bTQgMEExLjg0IDEuODQgMCAwIDEgNSAxMi4zYTIuNDkgMi40OSAwIDAgMSAuMS0uOCAyIDIgMCAwIDEgMS44LTEuMiAyIDIgMCAwIDEgMiAxLjkgMi4wNyAyLjA3IDAgMCAxLTIgMnptMy45LS44YTEuMDkgMS4wOSAwIDAgMCAuOS0uNmgxYTIgMiAwIDAgMS0zLjktLjQgMS45MiAxLjkyIDAgMCAxIDMuOC0uNGgtMWEuOTEuOTEgMCAwIDAtLjktLjYgMS4wOSAxLjA5IDAgMCAwLTEgMS4xIDEuMTkgMS4xOSAwIDAgMCAxLjEuOXoiPjwvcGF0aD4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=');
}

.is-img::before {
  background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOSAxNyI+CiAgPGc+CiAgICA8cGF0aCBkPSJNMTUgMEg0YTQgNCAwIDAgMC00IDR2OWE0IDQgMCAwIDAgNCA0aDExYTQgNCAwIDAgMCA0LTRWNGE0IDQgMCAwIDAtNC00ek0zLjEgMTQuM2gtLjl2LTMuN2guOXptNC4zIDBoLS45di0yLjhsLS44IDIuOGgtLjZsLS44LTIuOHYyLjhoLS44di0zLjdoMS4ybC44IDIuNC43LTIuNGgxLjJ6bTIuMiAwYTIgMiAwIDAgMS0yLTEuOSAxLjkyIDEuOTIgMCAwIDEgMi0xLjkgMi4xIDIuMSAwIDAgMSAxLjkgMS4zaC0xLjFhLjc5Ljc5IDAgMCAwLS44LS41IDEgMSAwIDAgMC0xIDEuMSAxIDEgMCAwIDAgMSAxLjEuOC44IDAgMCAwIC45LS42SDkuNHYtLjhoMi4ydi4yYTEuOTQgMS45NCAwIDAgMS0yIDJ6IiBmaWxsPSIjZmZmIj48L3BhdGg+CiAgPC9nPgo8L3N2Zz4K');
}

.form {
  margin: 0 var(--col-1);
  width: auto;

  &__heading {
    margin-bottom: var(--spacing-60);

    @include media(min-tablet) {
      width: vw(1440, 540);
    }
  }

  &__content {
    display: grid;
    grid-gap: var(--col-1);
    margin-bottom: var(--spacing-50);

    @include media(min-tablet) {
      grid-template-columns: vw(1440, 540) auto;
    }
  }

  form {
    display: grid;
    grid-gap: var(--spacing-30);

    @include media(min-tablet) {
      grid-template-columns: vw(1440, 600) auto;

      .is-100 {
        grid-column: 1 / 3;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 0;
    font-size: var(--font-s-subheading);
    font-weight: var(--font-w-bold);
    line-height: var(--lh-subheading);

    @include media(min-tablet) {
      margin-bottom: var(--spacing-20);
    }
  }

  input,
  textarea {
    padding: var(--spacing-30);
    width: 100%;
    border: none;
  }

  .wpcf7-submit {
    padding: var(--spacing-20);
    width: vw(360, 200);
    font-size: vw(360, 16);
    font-weight: var(--font-w-bold);
    line-height: var(--lh-subheading);
    background: var(--color-yellow);

    @include media(min-tablet) {
      float: right;
      width: vw(1440, 200);
      font-size: vw(1440, 16);
    }
  }

  .screen-reader-response {
    display: none;
  }

  .wpcf7-not-valid-tip {
    display: block;
    padding: var(--spacing-10);
    width: 100%;
    color: var(--color-white);
    background: var(--color-pink);
  }

  .row {
    @include media(min-tablet) {
      grid-column: 1 / 3;
    }
  }
}

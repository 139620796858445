.header {
  &.has-bg-image::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;

    visibility: hidden;
    //background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    background: rgba(0, 0, 0, 0.5);

    opacity: 0;
    transform: translateX(-50%);

    transition: opacity 1000ms;
  }

  &.header--overlay::before {
    visibility: visible;

    opacity: 1;

    transform: translateX(0);
  }

  &.is-text {
    padding: vw(360, 230) 0 vw(360, 170) 0;
    background-color: var(--color-grey-light);

    @include media(min-tablet) {
      padding: vw(1440, 245) 0 vw(1440, 115) 0;
    }
  }

  &__img-wrap {
    z-index: 1;
    opacity: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  &__wrap {
    grid-column: 2 / 12;
    position: relative;
    z-index: 3;

    @include media(min-tablet) {
      grid-column: 2 / 8;
    }

    > * {
      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
  }

  &.is-search &__wrap {
    @include media(min-tablet) {
      grid-column: 2 / -2;
    }
  }

  &.has-bg-image {
    padding: vw(360, 230) 0 vw(360, 170) 0;

    @include media(min-tablet) {
      padding: vw(1440, 375) 0 vw(1440, 115) 0;
    }
  }

  &.is-text &__subheading {
    color: var(--color-pink);
  }

  &.has-bg-image &__heading {
    color: var(--color-white);
  }

  &.has-bg-image &__subheading {
    color: var(--color-yellow);
  }

  &__button-wrap,
  &__body {
    margin-top: vw(360, 40);

    @include media(min-tablet) {
      margin-top: vw(1440, 40);
    }
  }

  &.has-cta {
    @include media(max-tablet) {
      padding-bottom: 0;
    }
  }

  &__button-wrap {
    grid-column: 2 / 12;
    z-index: 3;

    @include media(max-tablet) {
      order: 2;
    }

    > * {
      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }

    .button {
      @include media(max-tablet) {
        padding: vw(360, 15) vw(360, 20);
        width: 100%;
        height: auto;
        white-space: normal;
        text-align: center;

        &:last-child {
          margin-top: vw(360, 20);
        }
      }
    }
  }
}

.header__cta {
  grid-column: 1 / 13;
  z-index: 3;
  margin: vw(360, 30) 0 0 0;
  padding: vw(360, 30);
  background: var(--color-yellow);
  opacity: 0;

  @include media(max-tablet) {
    order: 3;
  }

  @include media(min-tablet) {
    justify-self: end;
    grid-column: 9 / 12;
    margin: 0;
    padding: vw(1440, 40) vw(1440, 30) vw(1440, 30);
    width: vw(1440, 300);
  }

  &-heading,
  &-body {
    padding: 0 var(--spacing-10);
  }

  &-heading {
    margin: 0 0 var(--spacing-15) 0;
    font-weight: var(--font-w-bold);
  }

  &-body {
    margin: 0 0 var(--spacing-30) 0;

    font-size: vw(360, 18);
    line-height: vw(360, 30);

    @include media(min-tablet) {
      margin: 0 0 var(--spacing-15) 0;

      font-size: vw(1440, 18);
      line-height: vw(1440, 30);
    }
  }

  .button {
    width: 100%;
  }
}

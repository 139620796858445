.search-form {
  display: none;

  position: absolute;
  top: 0;

  pointer-events: none;

  @include media(min-tablet) {
    display: flex;
    justify-content: space-between;

    right: vw(1440, 60);
    width: vw(1440, 692);

    &::after {
      content: none;
    }
  }

  &__input {
    padding-left: 0;
    width: 100%;
    visibility: hidden;

    color: var(--color-white);

    background: none;

    opacity: 0;
    border: none;

    border-bottom: 1px solid var(--color-white);
  }

  &__btn {
    display: flex;

    position: absolute;
    top: 50%;
    right: 0;

    pointer-events: all;

    transform: translateY(-50%);

    svg {
      width: vw(360, 22);
      height: vw(360, 24);

      @include media(min-tablet) {
        width: vw(1440, 22);
        height: vw(1440, 24);
      }
    }

    &--close {
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &.is-active {
    pointer-events: auto;

    & .search-form__input {
      visibility: visible;
      opacity: 1;
    }

    & .search-form__btn--close {
      visibility: visible;

      pointer-events: all;

      opacity: 1;
    }

    & .search-form__btn--open {
      visibility: hidden;
      pointer-events: none;
      opacity: 0;
    }
  }

  &__as {
    position: absolute;
    top: 100%;
    width: 100%;
    background: white;

    @include media(min-tablet) {
      padding: var(--spacing-30) var(--spacing-15);
    }

    &.is-hidden {
      display: none;
    }

    li {
      &:not(:last-child) {
        margin: 0 0 var(--spacing-15);
      }
    }

    a {
      color: #979797;
      text-decoration: none;
      font-weight: var(--font-w-bold);

      &:hover {
        color: var(--color-green);
      }
    }
  }
}

.navigation.is-dark {
  .search-form {
    &__input {
      color: var(--color-black);
      border-bottom: 1px solid var(--color-black);
    }

    &__btn {
      svg {
        fill: var(--color-black);
      }
    }
  }
}

.navigation.is-light {
  .search-form {
    &__input {
      color: var(--color-white);
      border-bottom: 1px solid var(--color-white);
    }

    &__btn {
      svg {
        fill: var(--color-white);
      }
    }
  }
}

.navigation {
  .mobile {
    .search-form {
      display: block;

      position: absolute;
      top: vw(360, 15);
      left: vw(360, 35);

      pointer-events: all;

      &__input {
        visibility: visible;
        color: var(--color-black);
        opacity: 1;

        border-color: var(--color-black);
      }

      &__btn svg {
        fill: var(--color-black);
      }
    }
  }
}

.footer__container {
  grid-row-gap: var(--spacing-60);

  padding: var(--spacing-60) 0;

  @include media(min-tablet) {
    grid-template-columns: repeat(auto-fit, minmax(calc(var(--col-3) - var(--spacing-60)), 1fr));
    grid-column-gap: var(--spacing-60);

    padding: var(--col-1);
  }
}

.footer__col {
  @include media(max-tablet) {
    grid-column: 2 / 12;
  }
}

.footer__col--privacy,
.footer__col--copy,
.footer__col--social {
  align-self: center;
}

.footer__col--privacy {
  @include media(min-tablet) {
    order: 2;
  }
}

.footer__col--copy {
  @include media(max-tablet) {
    margin: calc(var(--spacing-45) * -1) 0 0;
  }

  @include media(min-tablet) {
    order: 1;
  }
}

.footer__col--social {
  @include media(min-tablet) {
    order: 0;
  }
}

.footer__logo {
  margin: 0 0 var(--spacing-60);

  height: vw(360, 37);
  width: vw(360, 92);

  @include media(min-tablet) {
    margin: 0 0 var(--spacing-30);
    height: vw(1440, 55);
    width: vw(1440, 137);
  }
}

.footer__list {
  padding: 0;

  list-style: none;
}

.footer__list--icons {
  display: flex;
  align-items: start;

  @include media(max-tablet) {
    justify-content: space-between;
  }
}

.footer__list--icons .footer__list-item {
  display: inline-block;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }
}

.footer__list-link {
  text-decoration: none;
  font-weight: var(--font-w-medium);

  &:hover {
    text-decoration: underline;
  }
}

.footer__list--lg .footer__list-link {
  // font-size: 7.123vw; // 26px
  font-size: vw(360, 23);
  line-height: 10.959vw; // 40px
  font-weight: var(--font-w-bold);

  transition: color 200ms ease;
  will-change: color;

  &:hover {
    color: var(--color-green);
  }

  @include media(min-tablet) {
    // font-size: 2.083vw;
    font-size: vw(1440, 27);
    line-height: 3.125vw;
  }
}

.footer__list-icon--fb {
  width: 22% !important;
}

.footer__list-icon--ig {
  width: 40% !important;
}

.footer__list-icon--tw {
  width: 40% !important;
}

.footer__list-icon--in {
  fill: var(--color-yellow);
}

.footer__list-icon--yt {
  width: 45% !important;
}

.footer__link {
  grid-column: 2 / 12;

  text-transform: uppercase;

  @include media(min-tablet) {
    grid-column: 1;
    grid-row: 3;
  }

  a {
    font-weight: 300;
    text-decoration: none;
  }
}

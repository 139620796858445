.c {
  position: relative;
  margin: -2px 0;

  &.is-light {
    padding: var(--spacing-90) 0;
    background-color: var(--color-grey-light);
  }

  &.is-light + &.is-light {
    padding-top: 0;
  }

  &.is-dark {
    background-color: var(--color-black);
  }

  &.is-blue {
    background-color: var(--color-blue);
  }
}

.specs {
  &__container {
    grid-column: 2 / 12;
    display: grid;
    grid-gap: 0 var(--spacing-60);

    @include media(min-tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &__item {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    padding-bottom: var(--spacing-30);
    margin-bottom: var(--spacing-30);
    border-bottom: 1px solid var(--color-black);

    @include media(min-tablet) {
      justify-content: inherit;
    }

    &.no-border {
      @include media(min-tablet) {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
      }
    }
  }

  &__heading {
    margin-left: var(--spacing-15);

    span {
      font-weight: bold;
    }
  }

  &__icon {
    height: auto;
    width: var(--spacing-60);
  }
}

:root {
  // colors
  --color-white: #fff;
  --color-black: #111;
  --color-pink: #fc1f64;
  --color-blue: #1474d5;
  --color-blue-light: #ddecf0;
  --color-green: #169898;
  --color-yellow: #feed00;
  --color-grey-light: #f9f9f9;

  // spacings
  --spacing-5: #{vw(360, 5)};
  --spacing-10: #{vw(360, 10)};
  --spacing-15: #{vw(360, 15)};
  --spacing-20: #{vw(360, 20)};
  --spacing-30: #{vw(360, 30)};
  --spacing-40: #{vw(360, 40)};
  --spacing-45: #{vw(360, 45)};
  --spacing-50: #{vw(360, 50)};
  --spacing-60: #{vw(360, 60)};
  --spacing-75: #{vw(360, 75)};
  --spacing-90: #{vw(360, 90)};
  --spacing-120: #{vw(360, 120)};
  --spacing-150: #{vw(360, 150)};
  --spacing-180: #{vw(360, 180)};
  --spacing-210: #{vw(360, 210)};
  --spacing-240: #{vw(360, 240)};

  --nav-button-size: 70px;

  --col-0-5: calc(100vw / 24 * 1);
  --col-1: calc(100vw / 12 * 1);
  --col-2: calc(100vw / 12 * 2);
  --col-3: calc(100vw / 12 * 3);
  --col-4: calc(100vw / 12 * 4);
  --col-5: calc(100vw / 12 * 5);
  --col-6: calc(100vw / 12 * 6);
  --col-7: calc(100vw / 12 * 7);
  --col-8: calc(100vw / 12 * 8);
  --col-9: calc(100vw / 12 * 9);
  --col-10: calc(100vw / 12 * 10);
  --col-11: calc(100vw / 12 * 11);
  --col-12: calc(100vw / 12 * 12);

  --vh: 100vh;

  // easings
  --ease-expo-in: cubic-bezier(0.95, 0.05, 0.795, 0.035);
  --ease-expo-out: cubic-bezier(0.19, 1, 0.22, 1);
  --ease-expo-in-out: cubic-bezier(1, 0, 0, 1);
  --ease-power3-in: cubic-bezier(0.895, 0.03, 0.685, 0.22);
  --ease-power3-out: cubic-bezier(0.165, 0.84, 0.44, 1);
  --ease-power3-in-out: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-quart-in-out: cubic-bezier(0.77, 0, 0.175, 1);
  --ease-smooth-in-out: cubic-bezier(0.8, 0.16, 0.41, 0.86);
  --fast-ease-in: cubic-bezier(0.54, 0.03, 0.27, 0.91);
  --ease-internal: cubic-bezier(0.34, 0, 0.18, 1);
  --ease-link: cubic-bezier(0.6, 0, 0.4, 1);

  // fonts
  --font-f-body: 'ITC Avant Garde Gothic Std', arial, helvetica, sans-serif;
  --font-f-heading: 'ITC Avant Garde Gothic Std', arial, helvetica, sans-serif;
  --font-f-brand: 'Judgement', arial, helvetica, sans-serif;

  --font-w-bold: 700;
  --font-w-regular: 500;

  --font-s-heading-xl: #{vw(360, 52)};
  --lh-heading-xl: #{vw(360, 52)};

  --font-s-heading-lg: #{vw(360, 38)};
  --lh-heading-lg: #{vw(360, 48)};

  --font-s-heading-md: #{vw(360, 33)};
  --lh-heading-md: #{vw(360, 44)};

  --font-s-heading-sm: #{vw(360, 30)};
  --lh-heading-sm: #{vw(360, 32)};

  --font-s-heading-xs: #{vw(360, 24)};
  --lh-heading-xs: #{vw(360, 37)};

  --font-s-intro: #{vw(360, 20)};
  --lh-intro: #{vw(360, 32)};

  --font-s-subheading: #{vw(360, 20)};
  --lh-subheading: #{vw(360, 24)};

  --font-s-intro: #{vw(360, 20)};
  --lh-intro: #{vw(360, 32)};

  --font-s-body: #{vw(360, 16)};
  --lh-body: #{vw(360, 26)};

  --font-s-menu: #{vw(360, 16)};
  --lh-menu: #{vw(360, 26)};

  --font-s-button: #{vw(360, 16)};
  --lh-button: #{vw(360, 18)};

  --font-s-quote: #{vw(360, 16)};
  --lh-quote: #{vw(360, 30)};

  @include media(min-tablet) {
    // spacings
    --spacing-5: #{vw(1440, 5)};
    --spacing-10: #{vw(1440, 10)};
    --spacing-15: #{vw(1440, 15)};
    --spacing-20: #{vw(1440, 20)};
    --spacing-30: #{vw(1440, 30)};
    --spacing-40: #{vw(1440, 40)};
    --spacing-45: #{vw(1440, 45)};
    --spacing-50: #{vw(1440, 50)};
    --spacing-60: #{vw(1440, 60)};
    --spacing-75: #{vw(1440, 75)};
    --spacing-90: #{vw(1440, 90)};
    --spacing-120: #{vw(1440, 120)};
    --spacing-150: #{vw(1440, 150)};
    --spacing-180: #{vw(1440, 180)};
    --spacing-210: #{vw(1440, 210)};
    --spacing-240: #{vw(1440, 240)};

    // fonts
    --font-s-heading-xl: #{vw(1440, 85)};
    --lh-heading-xl: #{vw(1440, 90)};

    --font-s-heading-lg: #{vw(1440, 85)};
    --lh-heading-lg: #{vw(1440, 95)};

    --font-s-heading-md: #{vw(1440, 55)};
    --lh-heading-md: #{vw(1440, 60)};

    --font-s-heading-sm: #{vw(1440, 50)};
    --lh-heading-sm: #{vw(1440, 55)};

    --font-s-heading-xs: #{vw(1440, 40)};
    --lh-heading-xs: #{vw(1440, 45)};

    --font-s-intro: #{vw(1440, 20)};
    --lh-intro: #{vw(1440, 30)};

    --font-s-subheading: #{vw(1440, 20)};
    --lh-subheading: #{vw(1440, 24)};

    --font-s-intro: #{vw(1440, 20)};
    --lh-intro: #{vw(1440, 30)};

    --font-s-body: #{vw(1440, 16)};
    --lh-body: #{vw(1440, 26)};

    --font-s-menu: #{vw(1440, 16)};
    --lh-menu: #{vw(1440, 26)};

    --font-s-button: #{vw(1440, 16)};
    --lh-button: #{vw(1440, 18)};

    --font-s-quote: #{vw(1440, 16)};
    --lh-quote: #{vw(1440, 30)};
  }
}

.share {
  padding: var(--spacing-120) 0;

  &__container {
    grid-column: 1 / 13;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    @include media(min-tablet) {
      flex-direction: row;
      grid-column: 3 / 11;
      justify-content: space-between;
    }
  }

  &__heading {
    @include media(max-tablet) {
      font-size: vw(360, 24) !important;
    }
  }

  &__icon {
    width: vw(360, 60);
    height: auto;

    @include media(min-tablet) {
      width: vw(1440, 60);
    }
  }

  &__socials {
    display: flex;
    margin-top: vw(360, 30);

    @include media(min-tablet) {
      margin-top: 0;
      margin-right: vw(1440, -30);
    }

    svg {
      margin: 0 var(--spacing-15);

      @include media(min-tablet) {
        margin: 0;
        margin-left: var(--spacing-60);
      }
    }
  }
}
